import React, {useRef, useEffect, useState, useContext} from 'react';
import {Handle, useStore} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {acceptedFileTypes} from '../../config';
import {ColorPicker} from '../ColorPicker';
import Select from 'react-select';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {ckEditorBasic} from '../../config';
import {User} from '../../states/actions';
import {InputComponent, SelectComp, TextareaComponent, PreviewComponent, NodeComponent, SelectComponent} from './Components'
import { GlobalStateContext } from '../GlobalState';

const zoomSelector = (s) => s.transform[2];


export const UploadBlock = (node) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);  
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const bot = useSelector(Chatbot.get());
  const {_id} = useSelector(User.get());
  const [previewMode, setPreviewMode] = useState(globalState.preview ? undefined : false);
  const zoom = useStore(zoomSelector);


  useEffect(() => {
    setPreviewMode(globalState.preview);
}, [globalState.preview]);

  if (!block) {
    return null;
  }
  const {data} = block;


  return (
    <>

      {!previewMode &&(
          
          <NodeComponent bot={bot} block={block} name="Upload" icon="upload" data={data} node={node} start={start} zoom={zoom}/>
      )}

      {previewMode && (
       // <div className={`block-body node`} onClick={review ? null : () => onEdit(node)}>
              <PreviewComponent bot={bot} data={data} node={node} start={start}/>
       // </div>
      )}

    
    </>
  );
};

export const UploadEditor = ({nodeid, block, data, options}) => {


  if (!block) {
    return null;
  }


 
  const handleBlur = (e) => {   
    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
  };
  return(
    <>
              
              <div className='section'>UPLOAD</div>


              <InputComponent data={data} name="Upload file name" dataname="filePrefix" onBlur={handleBlur}/>
              <InputComponent data={data} name="File size limit (mb)" dataname="sizeLimit" onBlur={handleBlur}/>


                <label htmlFor="fileSizeLimit" className="form-label">
                  Accepted File Type
                </label>
                <Select
                  id="acceptedTypes"
                  name="acceptedTypes"
                  placeholder="Select File Type"
                  className=" form-control-sm editor-select"
                  
                  classNamePrefix="editor-select"
                 
                  isMulti
                  options={acceptedFileTypes}
                  value={acceptedFileTypes.filter((item) => data?.acceptedTypes?.includes(item.value))}
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {assign: {...data, acceptedTypes: option.map((i) => i.value)}});
                  }}
                />
              


    
    </>
  )

}