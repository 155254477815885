import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {CSVExport} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {axios} from '../libs';
import moment from 'moment';
import * as XLSX from 'xlsx';

const {ExportCSVButton} = CSVExport;

const paginationConfig = {
  page: 1,
  sizePerPage: 100,
  showTotal: true,
  prePageText: 'Previous',
  nextPageText: 'Next',
  sizePerPageList: [
    {
      text: '25',
      value: 25,
    },
    {
      text: '50',
      value: 50,
    },
    {
      text: '100',
      value: 100,
    },
    {
      text: '500',
      value: 500,
    },
    {
      text: '1000',
      value: 1000,
    },
  ],
};

export const Conversation = () => {
  const {chatbotId} = useParams();
  const [data, setData] = useState([]);
  const [paginationOptions, setpaginationOptions] = useState({});
  const [_columns, setcolumns] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {conversations, columns, occurance, total} = await axios.get(
          // `/chat/${chatbotId}/en?limit=5&project=-messages -userIp`,
          `/chat/${chatbotId}/en?project=-messages -userIp`,
        );

        console.log(columns, occurance)
        
        _setColumnsData(columns, occurance);
        setData(conversations);
        setpaginationOptions({...paginationConfig, totalSize: total});
      } catch (error) {
        return error;
      }
    };
    fetchData();
  }, [chatbotId]);

  const onTableChange = (type, {sortField, sortOrder, page, sizePerPage}) => {
    if (type === 'sort') {
      // sortData(sortField, sortOrder);
    } else {
      // paginateData(page, sizePerPage);
    }
  };

  const _setColumnsData = (columns, occurance) => {
    const _columnData = columns
      // .filter((a) => !a.includes('fileName-') && !a.includes('booking'))
      .filter((a) => !a.includes('fileName-'))
      .map((i) => {
        if (i === 'booking') {
          return {
            dataField: `data.${i}`,
            text: i,
            formatter: (a, data, c) => {
              return `EventId: ${data.data?.booking?.event_id || ''} | ProjectId: ${
                data.data?.booking?.project_id || ''
              }`;
            },
          };
        } else if (i === 'chatProgressId') {
          return {
            dataField: `${i}`,
            text: `${i}`,
            formatter: (value, data, c) => {
              return value ? `https://app.hugo.legal/hugoapp?bot=${chatbotId}&pid=${value}` : '';
            },
            csvFormatter: (value, row, rowIndex) => {
              return value ? `https://app.hugo.legal/hugoapp?bot=${chatbotId}&pid=${value}` : '';
            },
          };
        } else {
          if (occurance) {
            return {
              dataField: `data.${i}`,
              // with occurance text: `${i} ( ${occurance[0][i]} ) `,
              text: `${i}`,
              formatter: (value, row, rowIndex) => {
                return value ? value : '';
              },
              csvFormatter: (value, row, rowIndex) => {
                return value ? value : '';
              },
            };
          } else {
            return {
              dataField: `data.${i}`,
              text: `${i}`,
            };
          }
        }
      });
    _columnData.unshift({
      dataField: `createdAt`,
      text: 'Date/Time',
      style: {
        width: '6%',
      },
      formatter: (value) => {
        return moment(value).format('D.M.YYYY HH:mm');
      },
    });
    _columnData.push({dataField: `_id`, text: '_id'});
    setcolumns(_columnData);
  };

  // const paginateData = async (page, sizePerPage) => {
  //   const updatedUrl = `/chat/${chatbotId}/en?project=-messages -userIp&limit=${sizePerPage}&page=${page}`;
  //   const {...data} = await axios.get(updatedUrl);
  //   setpaginationOptions({...paginationConfig, page, sizePerPage});
  //   setData(data['conversations']);
  //   _setColumnsData(data['columns']);
  // };

  const handleDeleteDataSheet = async () => {
    window.nConfirm({
      title: 'Warning',
      text: 'Are you sure you want to delete the datasheet?',
      icon: 'fa-exclamation-circle',
      onYes: async () => {
        const url = `/chat/${chatbotId}/delete/conversation?project=-messages`;
        const {ok} = await axios.delete(url);
        if (ok) setData([]);
      },
    });
  };

  const renameVariable = async () => {
    try {
      const oldName = prompt(`Enter the variable name that you want to rename.`);
      const newName = prompt(`Enter the new new name`);

      const payload = {
        oldName,
        newName,
      };

      const {conversations, columns, occurance, total} = await axios.put(`/chat/${chatbotId}/renamevariable`, payload);
      _setColumnsData(columns, occurance);
      setData(conversations);
      setpaginationOptions({...paginationConfig, totalSize: total});
      window.nSuccess({text: 'Renamed successfully'});
    } catch (error) {
      console.error(error);
    }
  };

  const updateStatistics = async () => {
   /*  try {
      const oldName = prompt(`Enter the variable name that you want to rename.`);
      const newName = prompt(`Enter the new new name`);

      const payload = {
        oldName,
        newName,
      };

      const {conversations, columns, occurance, total} = await axios.put(`/chat/${chatbotId}/renamevariable`, payload);
      _setColumnsData(columns, occurance);
      setData(conversations);
      setpaginationOptions({...paginationConfig, totalSize: total});
      window.nSuccess({text: 'Renamed successfully'});
    } catch (error) {
      console.error(error);
    } */
  };

  const flattenData = (data) => {
    return data.map(item => {
        // Create a new object combining the item and its nested 'data'
        const flattened = { ...item, ...item.data };

        // Convert values to their appropriate types
        Object.keys(flattened).forEach(key => {
            const value = flattened[key];
            // Check if the value is a number and convert it
            if ( /^[0-9]+$/.test(value) && value.trim() !== '') {
                flattened[key] = Number(value);
            } else {
                flattened[key] = value;
            }
        });

        // Remove the original nested 'data' object
        delete flattened.data;

        return flattened;
    });
};

const handleExportToExcel = () => {
    try {
        const flattenedData = flattenData(data);
        const worksheet = XLSX.utils.json_to_sheet(flattenedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "exportedData.xlsx");
    } catch (error) {
        console.error("Error exporting to Excel:", error);
    }
};

  return (
    <div className="row">
      <div className="col-12">
        <div className="x_panel">
          <div className="x_title">
            <h2>Conversation</h2>
            <div className="clearfix"></div>
          </div>
          <div className="x_content">
            <div className="row justify-content-end">
              <div className="col-12 overflow-auto" style={{height: 'calc(100vh - 115px)'}}>
                {!!_columns.length && !!data.length && (
                  // <BootstrapTable
                  //   remote
                  //   hover
                  //   striped
                  //   keyField="_id"
                  //   noDataIndication="No data found"
                  //   data={data}
                  //   columns={_columns}
                  //   rowClasses="table-row"
                  //   onTableChange={onTableChange}
                  //   pagination={paginationFactory(paginationOptions)}
                  // />
                  <ToolkitProvider keyField="_id" data={data} columns={_columns} exportCSV>
                    {(props) => (
                      <>
                        <ExportCSVButton {...props.csvProps} className="btn btn-success btn-sm">
                          Export CSV
                        </ExportCSVButton>
                        <button className="btn btn-primary btn-sm m-2" onClick={handleExportToExcel}>
                        Export to Excel
                        </button>
                        <button className="btn btn-danger btn-sm m-2" onClick={handleDeleteDataSheet}>
                          Delete Datasheet
                        </button>
                        <button className="btn btn-warning btn-sm m-2" onClick={renameVariable}>
                          Rename a variable
                        </button>
                        <button className="btn btn-warning btn-sm m-2" onClick={updateStatistics}>
                          Update statistics with raw data
                        </button>
                        <hr />
                        <BootstrapTable
                          {...props.baseProps}
                          // remote
                          hover
                          striped
                          onTableChange={onTableChange}
                          pagination={paginationFactory(paginationOptions)}
                        />
                      </>
                    )}
                  </ToolkitProvider>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
