import React, {useState} from 'react';
import {Field} from 'formik';
import {DataTable, Form} from '../components';
import {acceptFiles} from '../config';
import {axios} from '../libs';

export const Documents = React.memo(() => {
  const [refetch, setrefetch] = useState(false);
  const [isMultipart, setIsMultipart] = useState(false);

  const initialValues = {
    name: '',
    document: null,
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSuccess = ({chatbot}) => {
    setrefetch(true);
  };

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name',
    },
  ];

  const onDelete = ({_id}) => {
    setrefetch(false);
    console.log(_id);
    window.nConfirm({
      title: 'Warning',
      text: 'Are you sure you want to delete this document?',
      icon: 'fa-exclamation-circle',
      onYes: () => {
       deleteDocument(_id);
      },
    });
  };

  const deleteDocument = async (id) => {
    const deleteId = id;
    await axios.post(`/user/deleteDocument`, {deleteId});
    setrefetch(true);
  };

  return (
    <>
   
       {/*
       <div className="row">
       <div className="col-md-6">
          <div className="x_panel">
            <div className="x_title">
              <h2>New Document</h2>
              <div className="clearfix"></div>
            </div>
            <div className="x_content">
              <br />
              <Form
                enctype="multipart/form-data"
                multipart={isMultipart}
                url={isMultipart ? '/user/documents/upload' : '/user/documents'}
                initialValues={initialValues}
                onSuccess={onSuccess}
              >
                <div className="row mb-3">
                  <label htmlFor="name" className="col-3 col-form-label">
                    Document Name
                  </label>
                  <div className="col-9">
                    <Field id="name" name="name" type="text" className="form-control" />
                  </div>
                </div>

                <div className="row mb-3">
                  <label htmlFor="document" className="col-3 col-form-label">
                    Select Document
                  </label>
                  <div className="col-9">
                    <Field name="document">
                      {({field, form}) => (
                        <input
                          onChange={(event) => {
                            const {
                              target: {
                                files: [file],
                              },
                            } = event;
                            form.setFieldValue('document', file);
                            setIsMultipart(true);
                          }}
                          accept={acceptFiles.toString()}
                          type="file"
                          name="document"
                          className="form-control"
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className="ln_solid"></div>
                <div className="col-md-9 col-sm-9  offset-md-3">
                  <button type="submit" className="btn btn-success">
                    Save
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div> 
         <div className="col-md-6">
          <div className="x_panel">
            <div className="x_title">
              <h2>Documents</h2>
              <div className="clearfix"></div>
            </div>
            <div className="x_content"></div>
            <DataTable
              onDelete={() => {}}
              refetch={refetch}
              navigateTo="https://docs.google.com/document/d"
              url="/user/documents"
              dataKey="documents"
              tableHeading="Documents"
              columns={tableColumns}
            />
          </div>
        </div>
      </div> */}




      <div className="row table_v2">
        <div className="col">
          <div className="x_panel chatbot-builder">

            <div className="header-menu d-flex justify-content-between">

             {/*  <h2>
                Documents
              </h2> */}
             

              <div className="d-flex justify-content-end page-top-container">
                      <div className='btn-add link' onClick={openModal}>
                         Add Document
                      </div>
              </div>


             
            </div>


            <div className="x_content">

                <div className="page-container">



                      <DataTable
                    //onDelete={() => {}}
                    
                    onDelete={onDelete}
                    refetch={refetch}
                    navigateTo="https://docs.google.com/document/d"
                    url="/user/documents"
                    dataKey="documents"
                    tableHeading="Documents"
                    columns={tableColumns}
                  />
               </div>
            </div>
          </div>
        </div>
      </div>


      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="modalclose" onClick={closeModal}>
              &times;
            </span>

            <div className='modal_header mb-4 my-4'>
              <h2>Create new document</h2>
            </div>
          

            <Form
                enctype="multipart/form-data"
                multipart={isMultipart}
                url={isMultipart ? '/user/documents/upload' : '/user/documents'}
                initialValues={initialValues}
                onSuccess={onSuccess}
              >
                <div className="row mb-3">
                  <label htmlFor="name" className="form-label comp-header">
                    Document Name
                  </label>
                  <div className="">
                    <Field id="name" name="name" type="text" className="form-control" />
                  </div>
                </div>

                <div className="row mb-5">
                  <label htmlFor="document" className="form-label comp-header">
                    Select Document
                  </label>
                  <div className="">
                    <Field name="document">
                      {({field, form}) => (
                        <input
                          onChange={(event) => {
                            const {
                              target: {
                                files: [file],
                              },
                            } = event;
                            form.setFieldValue('document', file);
                            setIsMultipart(true);
                          }}
                          accept={acceptFiles.toString()}
                          type="file"
                          name="document"
                          className="form-control"
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <button className="light-btn" onClick={closeModal}>
                    Cancel
                  </button>
                  <button type="submit" className="main-btn">
                    Save
                  </button>
                </div>


              </Form>



          </div>
        </div>
      )}

  
</>
   
  );
});
