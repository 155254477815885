import React from 'react';
import {Handle} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {useSelector} from 'react-redux';
import Select from 'react-select';

import {Chatbot} from '../../states/actions';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {ColorPicker} from '../ColorPicker';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import {ckEditorBasic} from '../../config';

export const PipedriveBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  if (!block) {
    return null;
  }
  const variableOptions = Chatbot.getBlockVariables();
  const {
    data,
    data: {user = {}},
    editMode,
  } = block;
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fas fa-handshake"></i> Pipedrive
            </div>

            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(block.id)}
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">
            <div className="row mb-3">
              <label htmlFor="user" className="form-label d-none">
                User
              </label>
              <div className="col">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <Select
                  id="firstName"
                  name="firstName"
                  placeholder="Variable"
                  className="form-control form-control-sm react-select-container"
                  classNamePrefix="react-select"
                  options={variableOptions}
                  value={variableOptions.find((item) => item.value === user.firstName)}
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {user: {firstName: option.value}});
                  }}
                />
              </div>
              <div className="col">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <Select
                  id="lastName"
                  name="lastName"
                  placeholder="Variable"
                  className="form-control form-control-sm react-select-container"
                  classNamePrefix="react-select"
                  options={variableOptions}
                  value={variableOptions.find((item) => item.value === user.lastName)}
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {user: {lastName: option.value}});
                  }}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <Select
                  id="email"
                  name="email"
                  placeholder="Variable"
                  className="form-control form-control-sm react-select-container"
                  classNamePrefix="react-select"
                  options={variableOptions}
                  value={variableOptions.find((item) => item.value === user.email)}
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {user: {email: option.value}});
                  }}
                />
              </div>
              <div className="col">
                <label htmlFor="phone" className="form-label">
                  Phone Number
                </label>
                <Select
                  id="phone"
                  name="phone"
                  placeholder="Variable"
                  className="form-control form-control-sm react-select-container"
                  classNamePrefix="react-select"
                  options={variableOptions}
                  value={variableOptions.find((item) => item.value === user.phone)}
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {user: {phone: option.value}});
                  }}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="orgId" className="form-label">
                Orginization Id (e.g 37 for Hugo-dev)
              </label>
              <input
                type="input"
                id="orgId"
                name="orgId"
                className="form-control"
                placeholder="37"
                defaultValue={data.orgId}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {orgId: e.target.value});
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="caseType" className="form-label">
                Case Type
              </label>
              <input
                type="input"
                id="caseType"
                name="caseType"
                className="form-control"
                placeholder="Dieselgate"
                defaultValue={data.caseType}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {caseType: e.target.value});
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="source" className="form-label">
                Source
              </label>
              <input
                type="input"
                id="source"
                name="source"
                className="form-control"
                placeholder="Robot Lawyer"
                defaultValue={data.source}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {source: e.target.value});
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <div id="description">
                <CKEditor
                  config={ckEditorBasic}
                  editor={ClassicEditor}
                  data={data.description}
                  onBlur={(e, editor) => {
                    Chatbot.setBlockData(block.id, {description: editor.getData()});
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node text-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
          <p>
            <b>Case Type:</b> {data.caseType}
          </p>
          <p>
            <b>Source:</b> {data.source}
          </p>
          <p>
            <b>Orignization:</b> {data.orgId}
          </p>
          <p>
            <b>Description:</b>
            <div className="my-3" dangerouslySetInnerHTML={{__html: data.description}} />
          </p>
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
});
