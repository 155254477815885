import React from 'react';
import {Formik, Form as FormikForm} from 'formik';
import {axios} from '../libs';

export const Form = ({
  initialValues,
  onSuccess,
  onError,
  onValidationError,
  children,
  url,
  notifications = true,
  successNotification = true,
  errorNotification = true,
  multipart = false,
  usePUT = false,
  ...rest
}) => {
  const {nSuccess, nError} = window;
  const _onSumbit = async (values, actions) => {
    if (url) {
      const formData = new FormData();
      let response = {};
      if (multipart) {
        for (let [key, value] of Object.entries(values)) {
          formData.append(key, value);
        }
      }
      if (usePUT && values.hasOwnProperty('_id')) {
        response = await axios.put(`${url}/${values._id ?? values.id}`, multipart ? formData : values);
      } else {
        response = await axios.post(url, multipart ? formData : values);
      }
      let {error, message, ...data} = response;
      if (error) {
        if (errorNotification && notifications) {
          nError({title: 'Opps!', text: message, icon: 'fa-exclamation-triangle', error});
        } else {
          _handleErrors(error);
        }
      } else {
        actions.resetForm();
        onSuccess && onSuccess(data);
        if (successNotification && notifications) {
          nSuccess({title: 'Success!', text: message, icon: 'fa-check-square'});
        }
      }
    } else {
      onSuccess && onSuccess(values, actions);
    }
  };

  const _handleErrors = (res) => {
    const {status} = res;
    switch (status) {
      case 422: {
        onValidationError && onValidationError(res);
        break;
      }
      default: {
        onError && onError(res);
        nError({title: 'Opps!', text: res._message || res.message, icon: 'fa-exclamation-triangle'});
        break;
      }
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={_onSumbit} enableReinitialize={true} rest>
      <FormikForm autoComplete="off">{children}</FormikForm>
    </Formik>
  );
};
