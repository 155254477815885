import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';
import { GlobalStateProvider } from './components/GlobalState';


ReactDOM.render(
  <React.StrictMode>
     <GlobalStateProvider>
        <Provider store={store}>
          <Routes />
        </Provider>
    </GlobalStateProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
