import {configureStore} from '@reduxjs/toolkit';
import * as slices from './states/slices';

let reducer = {};
for (let slice of Object.values(slices)) {
  reducer[slice.name] = slice.reducer;
}

const store = configureStore({
  reducer,
});

export default store;
