import React, {useEffect, useState, useRef, useContext} from 'react';
import {Field} from 'formik';
import {filter as _filter} from 'lodash';

import {useHistory, useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {Form, DataTable} from '../components';
import Select from 'react-select';

import {languages, countries} from '../config';

import {Chatbot, User} from '../states/actions';
import {axios} from '../libs';


import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';


import {SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';

import { GlobalStateContext } from '../components/GlobalState';
import {InputComponent, SelectComp, SelectComponent, TextareaComponent} from '../components/ChatbotBuilder/Components'


const DragHandle = sortableHandle(() => <i className="dragicon"><img src="/images/dragbtn.png" alt="..." /></i>);

const SortableItem = SortableElement(({ value, handleInputChange, remove }) => (
  <div className="single-input mb-3"> 
    <DragHandle />
    <input
      type="text"
      className="form-control"
      defaultValue={value}
      onBlur={handleInputChange}
    />
    <button onClick={remove} className="fa fa-trash-alt icon red"></button>

  </div>
));




const SortableList = SortableContainer(({ items, handleInputChange, remove }) => (
  <ul>
    {items.map((item, index) => (
      <SortableItem
        key={item.id}
        index={index}
        value={item.value}
        handleInputChange={(e) => handleInputChange(e, index)}
        remove={(e) => remove(item.id)}
      />
    ))}
  </ul>
));


export const Bot = React.memo(() => {

  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [refetch, setrefetch] = useState(false);

  const {push} = useHistory();
  const {chatbotId} = useParams();




  const {blocks, ...data} = useSelector(Chatbot.get());
  const {_id} = useSelector(User.get());






  const [progress, setProgress] = useState([]); 
  const refStep = useRef(null);

  const selecto = useRef(null);


  function addStep(i){
    const id = Date.now().toString();
    const name = refStep.current.value;
    let newProgress = ""
    if(progress){
      setProgress([...progress, {id: id, label: name, value: name, step: (progress.length + 1).toString()}])
      newProgress = [...progress, {id: id, label: name, value: name, step: (progress.length + 1).toString()}]
    }else{
      setProgress([{id:id, label: name, value: name, step: '1'}])
      newProgress = [{id:id, label: name, value: name, step: '1'}]
     }
     console.log(newProgress)
    refStep.current.value = '';
    const data = {
      progress: newProgress
     };
     Chatbot.setChatbotData0(chatbotId, data);
  }
/*   const removeStep = async (id) => {
    setProgress(progress.filter((item) => item.id !== id))
    const newItems = [...progress].filter((item) => item.id !== id);
    const data = {
      progress: newItems
     };
     Chatbot.setChatbotData0(chatbotId, data);
     Chatbot.removeStep(ChatbotId, data);
  } */


  const removeStep = (id) => {
    setProgress((prevProgress) => {
      const newItems = prevProgress.filter((item) => item.id !== id);
      const data = {
        progress: newItems
      };
      Chatbot.setChatbotData0(chatbotId, data);
      return newItems;
    });
  };


  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = arrayMoveImmutable(progress, oldIndex, newIndex);
    const updateItems = newItems.map((item, index) => {
      return { ...item, step: (index + 1).toString() };
    });
    setProgress(updateItems);
    const data = {
      progress: updateItems
     };
    Chatbot.setChatbotData0(chatbotId, data);
  };
  const handleInputChange = async (e, index) => {
    const newItems = [...progress];
    newItems[index] = {
      id: progress[index].id,
      label: e.target.value,
      value: e.target.value,
    }
    setProgress(newItems);
    const data = {
      progress: newItems
     };
    Chatbot.setChatbotData0(chatbotId, data);
  };
  const handleDeleteDataSheet = async () => {
    window.nConfirm({
      title: 'Warning',
      text: 'Are you sure you want to delete the datasheet?',
      icon: 'fa-exclamation-circle',
      onYes: async () => {
        const url = `/chat/${chatbotId}/delete/conversation?project=-messages`;
        const {ok} = await axios.delete(url);
      },
    });
  };


  
  //could use onEdited?
  const InputChange = (event) => {
    const data = {[event.target.name]: event.target.value};
    Chatbot.setChatbotData0(chatbotId, data);
  };
  const SelectChange = (event) => {
    console.log(selecto)
    console.log(selecto.current.props.id, event.value)
    const data = {[selecto.current.props.id]: event.value};
    Chatbot.setChatbotData0(chatbotId, data);
  };

  

  useEffect(() => {
    if (chatbotId) {
      (async () => {
        const {blocks, ...data} = await Chatbot.fetch(chatbotId);
        Chatbot.set(data);
        console.log(data.progress)
        setProgress(data.progress)
        console.log(progress)
      })();
    } else {
      Chatbot.clear();
    }
  }, [chatbotId]);




  useEffect(() => {
    console.log(progress)
    setProgress(progress)
  }, [progress]);




 /*  const onSuccess = ({chatbot}) => {
    setrefetch(false);
    Chatbot.set(chatbot);
    if (chatbotId) {
      push('/chatbots');
      setrefetch(true);
    } else {
      push(`/chatbot-builder/${chatbot._id}`);
    }
  };

  const onDelete = ({_id}) => {
    setrefetch(false);
    window.nConfirm({
      title: 'Warning',
      text: 'Are you sure you want to delete this chatbot?',
      icon: 'fa-exclamation-circle',
      onYes: () => {
        deleteChatbot(_id);
      },
    });
  }; */

  const deleteChatbot = async (id) => {
    await axios.delete(`/chatbot/${id}`);
    setrefetch(true);
  };

/*   const onEdit = (data) => {

    console.log(chatbotId)
    if(chatbotId === undefined)
    {push(`chatbots/${data._id}`) }else
    {push(`${data._id}`)} 
  }; */






  function validateInputs(el) {
    console.log(el)
    el.forEach(key => {
      const inputElement = document.getElementById(key);
      console.log(key)
      if (inputElement) {
        if (inputElement.value.trim() !== "") {
          // Input contains information (is valid)
          inputElement.classList.add("valid");
          inputElement.classList.remove("invalid"); 
        } else {
          // Input is empty or contains only whitespace (is not valid)
          inputElement.classList.add("invalid");
          inputElement.classList.remove("valid"); 
          inputElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }
  });
  }


  





  return (
    <>
     




      <div className="row table_v2 page">
        <div className="col">
          <div className="x_panel chatbot-builder">

            <div className="header-menu d-flex justify-content-between">

         {/*    <h2>
                <b>{data.name}</b>
              </h2> */}
             

              <div className="d-flex justify-content-end page-top-container">
                     {/*  <div className='btn-add link'>
                         Add Document
                      </div> */}
              </div>


             
            </div>


            <div className="x_content">

                <div className="page-container">

                    <div className="settings-container">

                      <div className="col col-6 flex-column p-3">
                          <InputComponent data={data} name="Chatbot name" dataname="name" onBlur={InputChange}/>

                          <TextareaComponent data={data} name="Chatbot Description" dataname="description" onBlur={InputChange}/>


                          {/* <SelectComponent data={data} name="Primary language" dataname="primaryLang" onChange={InputChange} ref={selecto} placeholder="Primary language" options={languages}/>
                          */}

                          <div className="mb-2">
                            <label htmlFor={data.primaryLang} className="form-label comp-header">
                              Primary Language
                            </label>
                                <Select
                                  className="form-control form-control-sm editor-select"
                                  placeholder="Select language"
                                  id="primaryLang"
                                  classNamePrefix="editor-select"
                                  name="primaryLang"
                                  
                                  ref={selecto}
                                  options={languages}
                                  onChange={SelectChange}    
                                  value={languages.find((item) => item.value === data.primaryLang)}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      borderRadius: 0,
                                      border: 'none',
                                    }),
                                  }}
                                />
                          </div>

                          <button className="btn btn-danger btn-sm m-2" onClick={handleDeleteDataSheet}>
                              Delete Data
                            </button>
                         
                        </div>

                        <div className="col col-6 flex-column p-3">

                             <label htmlFor={data.primaryLang} className="form-label comp-header">
                              Add step
                            </label>  
                               <div id="progression" className="single-input mb-4">
                  
                           
                            
                              <input
                                type="text"
                                id="txtName"
                                className="form-control"
                                placeholder="value"
                                ref={refStep}
                                onKeyPress={(e) => (e.key === 'Enter' ? addStep(e) : e)}
                              // onBlur={(e) => addProgressStep(e)}
                                />
                          

                                  <span className="icon" onClick={(e) =>  addStep(e)}>
                                    <i className="fa fa-plus"></i>
                                  </span>
                              </div>    

                                {progress === "" || progress === undefined ? (
                                <></>
                                ) : (           
                                  <SortableList items={progress} onSortEnd={handleSortEnd} handleInputChange={handleInputChange} remove={removeStep}/>
                              )}
    
                        </div>
                    </div>
               </div>
            </div>
          </div>
        </div>
      </div>

  




    </>
  );
  
});
