import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Chatbot} from '../states/actions';

export const ChatbotVaribles = () => {
  const [blockVariables, setBlockVariables] = useState([]);
  const {chatbotId} = useParams();

  useEffect(() => {
    (async () => {
      try {
        const {blocks} = await Chatbot.fetch(chatbotId);
        console.log(`file: ChatbotVaribles.js | line 17 | blocks`, blocks);
        const blockVariables = Chatbot.getBlockVariables(blocks);
        console.log(`file: ChatbotVaribles.js | line 18 | blockVariables`, blockVariables);
        setBlockVariables(blockVariables);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [chatbotId]);

  return (
    <>
      <div className="px-2 py-2">
        {!blockVariables.length && <p>No variables found</p>}
        <ul className="list-group list-group-flush">
          {blockVariables.map((i, index) => (
            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
              <span dangerouslySetInnerHTML={{__html: i?.content}}></span>
              <div>
                <span
                  style={{fontSize: '12px'}}
                  className="badge bg-primary"
                  role="button"
                  onClick={(e) => {
                    window.navigator.clipboard.writeText(e.currentTarget.innerHTML);
                    window.nSuccess({text: 'Variable coppied to clipboard,<br/> Press (Ctrl + V) to paste'});
                  }}
                >
                  {i.label === 'hugo_redirect' ? `{${i.label}}` : `{{${i.label}}}`}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
