import React, { useEffect, useState } from 'react';
import { DataTable } from '../components'; // Adjust the path if necessary
import { axios } from '../libs/axios';

export const Invoices = () => {
  const [refetch, setrefetch] = useState(true);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  let data ;
/*   const fetchClients = async () => {
        try {
        data = await axios.get('/clients'); 
        console.log("THIS DATA: " + JSON.stringify(data, null, 2))
        setClients(data.clients);
        } catch (error) {
        console.error('Error fetching clients:', error);
        } finally {
        setLoading(false);
        }
  };

  useEffect(() => {
    fetchClients();
  }, []);
 */

    const onDelete = ({_id}) => {
      setrefetch(false);
      console.log("ID" , _id)
      window.nConfirm({
        title: 'Warning',
        text: 'Are you sure you want to delete this invoice?',
        icon: 'fa-exclamation-circle',
        onYes: () => {
          deleteInvoice(_id);
        },
      });
    };

    const deleteInvoice = async (id) => {
      await axios.delete(`/invoices/${id}`);
      setrefetch(true);
    };


  const tableColumns = [
    {
      dataField: 'invoiceNumber',
      text: 'Invoice no',
    },
    {
      dataField: 'status',
      text: 'status',
    },
    {
      dataField: 'firstname',
      text: 'Name',
    },
    {
        dataField: 'lastname',
        text: 'Name',
      },
    {
        dataField: 'amount',
        text: 'Amount',
      },
      {
        dataField: 'service',
        text: 'Service',
      },
      {
        dataField: 'progressurl',
        text: 'Client journey',
      },
    {
      dataField: 'createdAt',
      text: 'Registered At',
      formatter: (date) => new Date(date).toLocaleDateString(),
    },
  ];

  return (
    <div className="row table_v2">
      <div className="col p-0">
        <div className="x_panel chatbot-builder">
          <div className="header-menu d-flex justify-content-between">
            <h2>Invoices</h2>
          </div>
          <div className="x_content">
            <div className="page-container">
              {loading ? (
                <div>Loading...</div>
              ) : (
                <DataTable
                  refetch={refetch}
                  url="/invoices"
                  //onDelete={onDelete}
                  tableHeading="Invoices"
                  dataKey="invoices"
                  columns={tableColumns}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

