import { clientSlice } from '../slices';
import store from '../../store';
import { axios } from '../../libs';
const { nError } = window;

const Actions = clientSlice.actions;
const { dispatch, getState } = store;

const set = (data) => {
  dispatch(Actions.set(data));
};

const get = () => {
  return (state) => state.clients;
};

const fetchClients = async () => {
  try {
    const { clients } = await axios.get('/client');
    dispatch(Actions.set({ clients }));
    return clients;
  } catch (error) {
    nError({ title: 'Error fetching clients' });
    return false;
  }
};

export const Client = {
  set,
  get,
  fetchClients,
};