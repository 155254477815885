import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Login} from './pages';
import {ChatbotVaribles} from './pages/ChatbotVaribles';
import MainLayout from './pages/layouts/MainLayout';
import {renderIfAuthenticated} from './utils';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route
          path="/chatbot-variables/:chatbotId"
          exact
          render={(props) =>
            renderIfAuthenticated({
              component: <ChatbotVaribles />,
              ...props,
            })
          }
        />
        <Route
          render={(props) =>
            renderIfAuthenticated({
              component: <MainLayout {...props} />,
              ...props,
            })
          }
        />
      </Switch>
    </Router>
  );
};

export default Routes;
