import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {axios} from '../libs';
import {Chatbot} from '../states/actions';

export const SelectBot = () => {
  const [showPanel, setshowPanel] = useState(false);
  const [bots, setBots] = useState([]);
  const [blockVariables, setBlockVariables] = useState([]);

  useEffect(() => {
    async function fetchBots() {
      try {
        const {chatbots} = await axios.get('/chatbot');
        setBots(chatbots.map((i) => ({label: i.name, value: i._id})));
      } catch (error) {
        return error;
      }
    }
    fetchBots();
  }, []);

  const fetchBotVariables = async (chotbotId) => {
    try {
      const {blocks} = await Chatbot.fetch(chotbotId);
      const blockVariables = Chatbot.getBlockVariables(blocks);
      setBlockVariables(blockVariables);
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      {showPanel && (
        <div className="chat-preview">
          <div className="chat-header">
            <img src="/images/hugo.legal.png" alt="Hugo legal logo" className="img-fluid" width="100" />
            <div className="mx-2 header-options"></div>
          </div>
          <div id="chat-body" className="chat-body">
            <div className="px-2 py-2">
              <Select
                id="chatbotId"
                name="chatbotId"
                placeholder="Select Bot"
                options={bots}
                onChange={(option) => {
                  fetchBotVariables(option.value);
                }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: 0,
                  }),
                }}
              />
            </div>
            <div className="px-2 py-2">
              <p>Variables</p>
              {!blockVariables.length && <p>No variables found</p>}
              <ul className="list-group list-group-flush">
                {blockVariables.map((i) => (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    {/* <span dangerouslySetInnerHTML={{__html: i.data.content}}></span> */}
                    <span
                      className="badge bg-primary"
                      role="button"
                      onClick={(e) => {
                        window.navigator.clipboard.writeText(e.currentTarget.innerHTML);
                        window.nSuccess({text: 'Variable coppied to clipboard,<br/> Press (Ctrl + V) to paste'});
                      }}
                    >
                      &#123;&#123;{i.label}&#125;&#125;
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      <div className="chat-preview-button">
        <span className="btn btn-dark" onClick={() => setshowPanel(!showPanel)}>
          <i className="fas fa-robot fa-lg"></i>
        </span>
      </div>
    </>
  );
};
