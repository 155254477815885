import React, {useRef} from 'react';
import {Handle} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import {ColorPicker} from '../ColorPicker';

export const GTMBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());

  const refTxtName = useRef(null);
  const refTxtValue = useRef(null);
  if (!block) {
    return null;
  }
  const {
    data,
    editMode,
    data: {variables = {}},
  } = block;
  const {backgroundColor: bgColor, textColor: txtColor} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');

  const addDecisionField = (e) => {
    e.preventDefault();
    const id = Date.now().toString();
    const name = refTxtName.current.value;
    const value = refTxtValue.current.value;
    if (name !== '') {
      Chatbot.setBlockData(node.id, {variables: {[id]: {id, name, value}}});
      refTxtName.current.value = '';
      refTxtValue.current.value = '';
    }
  };

  const removeVariableField = (e, id) => {
    e.preventDefault();
    Chatbot.removeBlockVariable(node.id, id);
  };

  return (
    <>
      {editMode && (
        <div className="block nowheel" onDoubleClick={() => onEdit(node)}>
          <div className="block-header" style={{backgroundColor}}>
            <div style={{color: textColor}}>
              <i className="fa fa-tags"></i> GTM
            </div>

            <div className="pr-1 header-options">
              <i className="mx-1 badge bg-success" onClick={() => onSetStart(node)}>
                Set home
              </i>
              <i className="mx-1 badge bg-danger" onClick={() => onRemove(block.id)}>
                Remove
              </i>
              <i style={{color: textColor}} className="mx-1 icon fa fa-cog"></i>
              <ColorPicker
                color={backgroundColor}
                onChangeComplete={({hex}) => {
                  const textColor = isLightColor(hex) ? '#000000' : '#ffffff';
                  Chatbot.setBlockData(block.id, {meta: {backgroundColor: hex, textColor}});
                }}
              />
              <i
                style={{color: textColor}}
                className="ms-1 me-2 icon fa fa-times"
                onClick={() => onClose(block.id)}
              ></i>
            </div>
          </div>
          <div className="block-body nodrag">
            <div className="mb-3">
              <label htmlFor="event" className="form-label">
                Event
              </label>
              <input
                type="input"
                id="event"
                name="event"
                className="form-control"
                placeholder="custom-event-name"
                defaultValue={data.event}
                onBlur={(e) => {
                  Chatbot.setBlockData(block.id, {event: e.target.value});
                }}
              />
            </div>
            {Object.keys(variables)
              .sort()
              .map((key, i) => {
                const item = variables[key];
                return (
                  <div key={item.id} className="input-group mb-3">
                    <span className="input-group-text">Name</span>
                    <input
                      name={`variables.${item.id}.name`}
                      className="form-control"
                      placeholder="option"
                      defaultValue={item.name}
                      onBlur={(e) => {
                        Chatbot.setBlockData(node.id, {variables: {[item.id]: {id: item.id, name: e.target.value}}});
                      }}
                    />
                    <span className="input-group-text">Value</span>
                    <input
                      id={`${item.id}.value`}
                      name={`variables.${item.id}.value`}
                      className="form-control"
                      placeholder="value"
                      defaultValue={item.value}
                      onBlur={(e) =>
                        Chatbot.setBlockData(node.id, {variables: {[item.id]: {id: item.id, value: e.target.value}}})
                      }
                    />
                    <span
                      className="input-group-text btn btn-outline-danger border-0"
                      onClick={(e) => removeVariableField(e, item.id)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </span>
                  </div>
                );
              })}
            <div className="mb-3">
              <label htmlFor="variables" className="d-none">
                Variables
              </label>
              <div id="variables" className="input-group">
                <span className="input-group-text">Name</span>
                <input
                  type="text"
                  id="txtText"
                  className="form-control"
                  placeholder="option"
                  ref={refTxtName}
                  onKeyPress={(e) => (e.key === 'Enter' ? addDecisionField(e) : e)}
                />
                <span className="input-group-text">Value</span>
                <input
                  type="text"
                  id="txtValue"
                  className="form-control"
                  placeholder="value"
                  ref={refTxtValue}
                  onKeyPress={(e) => (e.key === 'Enter' ? addDecisionField(e) : e)}
                  onBlur={(e) => addDecisionField(e)}
                />
                <span
                  className="input-group-text btn btn-outline-success border-0"
                  onClick={(e) => addDecisionField(e)}
                >
                  <i className="fa fa-plus"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {!editMode && (
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className="node text-block"
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
          <b>Google Tag Manager Event</b>
          <div
            className="my-3 badge bg-success"
            style={{fontSize: '12px'}}
            dangerouslySetInnerHTML={{__html: data.event}}
          />
          <div className="">
            {Object.keys(variables)
              .sort()
              .map((key, i) => {
                const item = variables[key];
                return (
                  <>
                    <span>
                      {item.name} : {item.value}
                    </span>
                    <hr style={{margin: '5px'}} />
                  </>
                );
              })}
          </div>
        </div>
      )}
      <Handle id="source" className="block-handle" type="target" position="top" />
      <Handle id="next" className="block-handle" type="source" position="bottom" />
    </>
  );
});
