import React from 'react';
import {User} from '../../states/actions';

export const Sidebar = () => {
  const onDragStart = (e) => {
    let {type, blockType} = e.target.dataset;
    e.dataTransfer.setData('blockType', JSON.stringify({type, blockType}));
  };

  return (
    <>

      <div className="blocks-menu h-100">
      
        <p>Add content:</p>
        <div className="block-item" data-type="text" data-block-type="textBlock" onDragStart={onDragStart} draggable>
          <i><img src="/images/icons/text.png" alt="..." /></i>
          <span className="">Text</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>
        <div className="block-item" data-type="input" data-block-type="inputBlock" onDragStart={onDragStart} draggable>
        <i><img src="/images/icons/input.png" alt="..." /></i>
          <span className="">Input</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>
        <div
          className="block-item"
          data-type="multipleChoice"
          data-block-type="multipleChoiceBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i><img src="/images/icons/multipleChoice.png" alt="..." /></i>
          <span className="">Multiple Choice</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>
        <div
          className="block-item"
          data-type="decision"
          data-block-type="decisionBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i><img src="/images/icons/decision.png" alt="..." /></i>
          <span className="">Decision</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>
    
        <div
          className="block-item"
          data-type="review"
          data-block-type="reviewBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i><img src="/images/icons/review.png" alt="..." /></i>
          <span className="">Review</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>
       

      <p className='mt-4'> Add Action: </p>

        <div
          className="block-item"
          data-type="condition"
          data-block-type="conditionBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i><img src="/images/icons/condition.png" alt="..." /></i>
          <span className="">Condition</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>

        <div
          className="block-item"
          data-type="document"
          data-block-type="documentBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i><img src="/images/icons/document.png" alt="..." /></i>
          <span className="">Document</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>

        <div
          className="block-item"
          data-type="sheet"
          data-block-type="sheetBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i><img src="/images/icons/sheet.png" alt="..." /></i>
          <span className="">Sheet</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>

        <div className="block-item" data-type="calculation" data-block-type="calculationBlock" onDragStart={onDragStart} draggable>
        <i><img src="/images/icons/calculation.png" alt="..." /></i>
          <span className="">Calculation</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>

        <div className="block-item" data-type="data" data-block-type="dataBlock" onDragStart={onDragStart} draggable>
        <i><img src="/images/icons/data.png" alt="..." /></i>
          <span className="">Data</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>
        <div
          className="block-item"
          data-type="upload"
          data-block-type="uploadBlock"
          onDragStart={onDragStart}
          draggable
        >
         <i><img src="/images/icons/upload.png" alt="..." /></i>
          <span className="">Upload</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>

          <div className="block-item" data-type="email" data-block-type="emailBlock" onDragStart={onDragStart} draggable>
          <i><img src="/images/icons/email.png" alt="..." /></i>
          <span className="">Email</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>

        <div
          className="block-item"
          data-type="share"
          data-block-type="shareBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i><img src="/images/icons/share.png" alt="..." /></i>
          <span className="">Share</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>



        <div className="block-item" data-type="teleport" data-block-type="teleportBlock" onDragStart={onDragStart} draggable>
        <i><img src="/images/icons/teleport.png" alt="..." /></i>
          <span className="">Teleport</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>

        <div className="block-item" data-type="match" data-block-type="matchBlock" onDragStart={onDragStart} draggable>
        <i><img src="/images/icons/teleport.png" alt="..." /></i>
          <span className="">Matchmaking</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>
       
        <div className="block-item" data-type="ai" data-block-type="aiBlock" onDragStart={onDragStart} draggable>
        <i><img src="/images/icons/teleport.png" alt="..." /></i>
          <span className="">Ai</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>

        <div className="block-item" data-type="login" data-block-type="loginBlock" onDragStart={onDragStart} draggable>
        <i><img src="/images/icons/teleport.png" alt="..." /></i>
          <span className="">Login</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>

        <div className="block-item" data-type="calendar" data-block-type="calendarBlock" onDragStart={onDragStart} draggable>
        <i><img src="/images/icons/teleport.png" alt="..." /></i>
          <span className="">Calendar</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>
       


      
        <div
          className="block-item"
          data-type="comment"
          data-block-type="commentBlock"
          onDragStart={onDragStart} 
          draggable
        >
          <i><img src="/images/icons/comment.png" alt="..." /></i>
          <span className="">Comment</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>


        <p className='mt-4'>Add integrations: </p>
        <div
            className="block-item"
            data-type="payment"
            data-block-type="paymentBlock"
            onDragStart={onDragStart}
            draggable
          >
            <i><img src="/images/icons/payment.png" alt="..." /></i>
            <span className="">Payment</span>
            <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
       </div>


       <div
            className="block-item"
            data-type="scoro"
            data-block-type="scoroBlock"
            onDragStart={onDragStart}
            draggable
          >
            <i><img src="/images/icons/payment.png" alt="..." /></i>
            <span className="">Scoro</span>
            <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
       </div>


        <div className="block-item" data-type="sendy" data-block-type="sendyBlock" onDragStart={onDragStart} draggable>
          <span className="">Sendy</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>

        <div className="block-item" data-type="gtm" data-block-type="gtmBlock" onDragStart={onDragStart} draggable>
          <span className="">Google Tag Manager</span>
          <span className='drag'><img src="/images/draganddrop.png" alt="..." /></span>
        </div>

        {/* BLOCKS TO CHECK AND FIX */}

       {/* <div
          className="block-item"
          data-type="redirect"
          data-block-type="redirectBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i className="fas fa-directions"></i>
          <span className="">Redirect</span>
        </div> */}

       {/*  {User.isAdmin() && (
          <div
            className="block-item"
            data-type="booking"
            data-block-type="bookingBlock"
            onDragStart={onDragStart}
            draggable
          >
            <i className="fas fa-book"></i>
            <span className="">Booking</span>
          </div>
        )} */}

       
       {/*  {User.isAdmin() && (
          <div
            className="block-item"
            data-type="pipedrive"
            data-block-type="pipedriveBlock"
            onDragStart={onDragStart}
            draggable
          >
            <i className="fas fa-handshake"></i>
            <span className="">Pipedrive</span>
          </div>
        )} */}


        {/* <div className="block-item" data-type="scoro" data-block-type="scoroBlock" onDragStart={onDragStart} draggable>
          <i className="far fa-calendar-alt"></i>
          <span className="">Scoro</span>
        </div> */}
    
        
     








       {/* BLOCKS TO REMOVE 

        {User.isAdmin() && (
          <div
            className="block-item"
            data-type="hoiulaenu"
            data-block-type="hoiulaenuBlock"
            onDragStart={onDragStart}
            draggable
          >
            <i className="fas fa-square-root-alt"></i>
            <span className="">Hoiulaenu</span>
          </div>
        )}

        {User.isAdmin() && (
          <div
            className="block-item"
            data-type="dieselgate"
            data-block-type="dieselgateBlock"
            onDragStart={onDragStart}
            draggable
          >
            <i className="fas fa-square-root-alt"></i>
            <span className="">Dieselgate</span>
          </div>
        )} */}

        {/* <div
          className="block-item"
          data-type="richText"
          data-block-type="richTextBlock"
          onDragStart={onDragStart}
          draggable
        >
          <i className="far fa-edit"></i>
          <span className="">Rich Text</span>
        </div> */}

      </div>
    </>
  );
};
