import React, {useEffect, useRef} from 'react';
//import ReactFlow, {Background} from 'react-flow-renderer';
import ReactFlow, {Controls, Background} from 'reactflow';

import {axios} from '../libs';
import {Chatbot, User} from '../states/actions';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {nodeTypes} from '../components/ChatbotBuilder/nodeTypes';
import {Form} from '../components';

export const ChatbotReview = React.memo(() => {
  const reactFlowWrapper = useRef(null);
  const {name, blocks} = useSelector(Chatbot.get());
  const {submissionId} = useParams();

  useEffect(() => {
    window.collapseSidebar();
    axios.get(`chatbot/review/${submissionId}`).then(({chatbotReview}) => {
      const blocks = Chatbot.formatBlocks(chatbotReview.chatbot);
      Chatbot.set({...chatbotReview.chatbot, blocks, review: true});
    });
    //get chatbot blocks
  }, [submissionId]);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onClone = async (event) => {
    try {
      const {message} = await axios.post(`chatbot/clone`, {submissionId});
      window.nSuccess({title: 'Success!', text: message, icon: 'fa-check-square'});
    } catch (error) {
      window.nError({title: 'Opps!', text: error.message, icon: 'fa-exclamation-triangle', error});
    }
  };

  return (
    <>
      <div className="row">
        <div className="col p-0">
          <div className="x_panel chatbot-builder">
            <div className="x_title d-flex justify-content-between">
              <h2>
                Chat Builder: <b>{name}</b>
              </h2>
              {User.isAdmin() && (
                <button className="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#modalClone">
                  Clone This Bot
                </button>
              )}
            </div>
            <div className="x_content">
              <div className="row h-100">
                <div className="col-12 p-0" ref={reactFlowWrapper}>
                  <ReactFlow
                    nodesConnectable={false}
                    elementsSelectable={false}
                    elements={Object.values(blocks)}
                    snapToGrid={true}
                    nodeTypes={nodeTypes}
                    // onDrop={onDrop}
                    onDragOver={onDragOver}
                    // onElementsRemove={onBlockRemove}
                    // deleteKeyCode={46}
                    // onConnect={onConnect}
                    // onNodeDragStop={onNodeDragStop}
                  >
                    <Background gap={12} />
                  </ReactFlow>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalClone"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <Form url="/chatbot/submit" initialValues={{submissionId}}>
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="staticBackdropLabel">
                  Copy/Clone Chat Bot
                </h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <p>When you click submit, the system will make a copy this bot and make it available for Admins.</p>
                  <p>Then Admins would have the ability to change it or publish it.</p>
                  <p className="text-danger">
                    NOTE: It will ovveride the previously copy/cloned bot, but admin changes will still exists
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    onClone();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
});
