import React, { useEffect, useState } from 'react';
import { DataTable } from '../components'; // Adjust the path if necessary
import { axios } from '../libs/axios';

export const Clients = () => {
  const [refetch, setrefetch] = useState(true);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  let data ;
/*   const fetchClients = async () => {
        try {
        data = await axios.get('/clients'); 
        console.log("THIS DATA: " + JSON.stringify(data, null, 2))
        setClients(data.clients);
        } catch (error) {
        console.error('Error fetching clients:', error);
        } finally {
        setLoading(false);
        }
  };

  useEffect(() => {
    fetchClients();
  }, []);
 */

    const onDelete = ({_id}) => {
      setrefetch(false);
      window.nConfirm({
        title: 'Warning',
        text: 'Are you sure you want to delete this user?',
        icon: 'fa-exclamation-circle',
        onYes: () => {
          deleteUser(_id);
        },
      });
    };

    const deleteUser = async (id) => {
      await axios.delete(`/clients/${id}`);
      setrefetch(true);
    };


  const tableColumns = [
    {
      dataField: 'firstname',
      text: 'Name',
    },
    {
        dataField: 'lastname',
        text: 'Name',
      },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
        dataField: 'phone',
        text: 'Phone',
      },
      {
        dataField: 'conversations',
        text: 'Conversations',
        formatter: (cell, row) => {
          // Join conversations with <br> and return as HTML
          const conversationsHTML = row.conversations.map(convo => convo.toString()).join('<br>');
          return <div dangerouslySetInnerHTML={{ __html: conversationsHTML }} />;
        },
      },
    {
      dataField: 'createdAt',
      text: 'Registered At',
      formatter: (date) => new Date(date).toLocaleDateString(),
    },
  ];

  return (
    <div className="row table_v2">
      <div className="col p-0">
        <div className="x_panel chatbot-builder">
          <div className="header-menu d-flex justify-content-between">
            <h2>Clients</h2>
          </div>
          <div className="x_content">
            <div className="page-container">
              {loading ? (
                <div>Loading...</div>
              ) : (
                <DataTable
                  refetch={refetch}
                  url="/clients"
                  onDelete={onDelete}
                  tableHeading="Clients"
                  dataKey="clients"
                  columns={tableColumns}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

