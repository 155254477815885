import {
  TextBlock,
  InputBlock,
  ConditionBlock,
  DecisionBlock,
  TeleportBlock,
  SendyBlock,
  RedirectBlock,
  DocumentBlock,
  DataBlock,
  BookingBlock,
  EmailBlock,
  PipedriveBlock,
  ScoroBlock,
  UploadBlock,
  MultipleChoiceBlock,
  ReviewBlock,
  ShareBlock,
  GTMBlock,
  PaymentBlock,
  CalculationBlock,
  CommentBlock,
  SheetBlock,
  MatchBlock,
  AiBlock,
  LoginBlock,
  CalendarBlock,
} from './';

export const nodeTypes = {
  textBlock: TextBlock,
  inputBlock: InputBlock,
  conditionBlock: ConditionBlock,
  decisionBlock: DecisionBlock,
  teleportBlock: TeleportBlock,
  sendyBlock: SendyBlock,
  redirectBlock: RedirectBlock,
  documentBlock: DocumentBlock,
  dataBlock: DataBlock,
  bookingBlock: BookingBlock,
  emailBlock: EmailBlock,
  pipedriveBlock: PipedriveBlock,
  scoroBlock: ScoroBlock,
  uploadBlock: UploadBlock,
  multipleChoiceBlock: MultipleChoiceBlock,
  reviewBlock: ReviewBlock,
  shareBlock: ShareBlock,
  gtmBlock: GTMBlock,
  paymentBlock: PaymentBlock,
  calculationBlock: CalculationBlock,
  commentBlock: CommentBlock,
  sheetBlock: SheetBlock,
  matchBlock: MatchBlock,
  aiBlock: AiBlock,
  loginBlock: LoginBlock,
  calendarBlock: CalendarBlock,
};
