/**
 * @module plugin/CtaButton/CtaButtonUI
 */

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import buttonIcon from './addButton.svg';

/**
 * The bold UI feature. It introduces the Bold button.
 *
 * @extends module:core/plugin~Plugin
 */

export default class CtaButtonUI extends Plugin {
  /**
   * @inheritDoc
   */

  static get pluginName() {
    return 'CtaButtonUI';
  }

  /**
   * @inheritDoc
   */

  init() {

    const editor = this.editor;
    const t = editor.t;

    editor.ui.componentFactory.add('ctaButton', (locale) => {
      // we register this toolbar creating function under the name of 'bookmark'
      const insertCommand = editor.commands.get('insertCtaButton');
      const buttonView = new ButtonView(locale);

      buttonView.set({
        label: t('CTA button'),
        withText: true,
        tooltip: true,
        icon: buttonIcon,
      });

      buttonView.bind('isOn', 'isEnabled').to(insertCommand, 'value', 'isEnabled');

      // Execute the command when the button is clicked (executed)
      this.listenTo(buttonView, 'execute', () => {
        editor.execute('insertCtaButton');
        editor.editing.view.focus();
      });

      return buttonView;
    });
  }
}
