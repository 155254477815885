import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {Handle} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {Form} from '../';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onSuccess, onEdit, onSetStart, onRemove, onClose, isLightColor} from '../../helpers/block-helper';
import {Link} from 'react-router-dom';
import {IntNodeComponent, InputComponent, TextareaComponent, SelectComp} from './Components'



export const AiBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
 
 


  if (!block) {
    return null;
  }
  const {data} = block;

  return (
    <>
        <IntNodeComponent name="Ai" icon="ai" data={data} node={node} start={start}/>
    </>
  );
});

export const AiEditor = ({nodeid, block}) => {
  

 
  const {data} = block;

  if (!block) {
    return null;
  }

  const variableoptions = Chatbot.getBlockVariables();


  const handleBlur = (e) => {   
    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
  };


return (
  <>
         


          
              <div className='section'>Ai SISEND</div>
            
              
              <TextareaComponent data={data} name="Prompt" dataname="aiprompt" onBlur={handleBlur} rows="16"/>

              <InputComponent data={data} name="Waiting message" dataname="waitmessage" onBlur={handleBlur}/>

  

         
  </>
  

)
};