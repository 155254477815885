import React, { useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router';
import { axios } from '../libs';
import { defaultColumnsOption } from '../config';

export const DataTable = ({
  navigateTo,
  url,
  dataKey,
  tableHeading,
  columns,
  onDelete,
  onCopy,
  onEdit,
  onData,
  onPreview,
  refetch = false,
  actions,
  isPreview = false,
  defaultSorted = [{ dataField: 'createdAt', order: 'desc' }]
}) => {
  const [data, setData] = useState([]);
  const [sortField, setSortField] = useState(defaultSorted[0].dataField); // Track sort field
  const [sortOrder, setSortOrder] = useState(defaultSorted[0].order); // Track sort order
  const { push } = useHistory();

  // Fetch data and sort locally
  const fetchData = useCallback(async () => {
    try {
      // Adjust the limit parameter to fetch more rows
      const response = await axios.get(`${url}?limit=250`);
      let fetchedData = response[dataKey];
  
      // Sort the data locally
      fetchedData = sortData(fetchedData, sortField, sortOrder);
  
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching data", error);
      return error;
    }
  }, [dataKey, url, sortField, sortOrder]);

  // Sort the data locally
  const sortData = (data, sortField, sortOrder) => {
    return [...data].sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  };

  // Initial data fetch
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Refetch data if `refetch` prop changes
  useEffect(() => {
    if (refetch) {
      fetchData();
    }
  }, [refetch, fetchData]);

  // Handle table change events for sorting
  const onTableChange = (type, { sortField, sortOrder }) => {
    if (type === 'sort') {
      setSortField(sortField);
      setSortOrder(sortOrder);
    }
  };

  // Handle search input change
  const onSearch = async ({ target: { value } }) => {
    try {
      const updatedUrl = `${url}?name=${value}`;
      const response = await axios.get(updatedUrl);
      let searchedData = response[dataKey];
      searchedData = sortData(searchedData, sortField, sortOrder); // Sort after search
      setData(searchedData);
    } catch (error) {
      console.error("Error during search", error);
    }
  };

  // Define action column formatter
  const columnDataFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="row align-items-center">
        {onDelete && <span onClick={() => onDelete(row)} className="col btn-link-page">Delete</span>}
        {onPreview && <span onClick={() => onPreview(row)} className="col btn-link-page">Preview</span>}
        {onCopy && <span onClick={() => onCopy(row)} className="col btn-link-page">Copy</span>}
        {onData && <button onClick={() => onData(row)} className="col btn-page">Data</button>}
        {onEdit && <button onClick={() => onEdit(row)} className="col btn-page">Edit</button>}
      </div>
    );
  };

  // Define action column
  const columnFormatter = {
    dataField: '',
    text: 'Actions',
    formatter: columnDataFormatter,
  };

  // Define table row click events
  const tableEvents = {
    events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        if (!navigateTo) return;
        if (dataKey === 'documents') {
          window.open(`${navigateTo}/${row._id}/edit`, '_blank');
        } else {
          push(`${navigateTo}/${row._id}`);
        }
      },
    },
  };

  return (
    <>
      <div className="row justify-content-start">
        <div className="col-sm-4 mb-2 datasearch">
          <input onChange={onSearch} placeholder="Search" className="form-control" />
        </div>
        <div className="col-sm-12">
          <BootstrapTable
            remote={{ sort: false }} // Disable remote sorting
            hover
            striped
            keyField="_id"
            noDataIndication="No data found"
            data={data}
            columns={columns
              .map((column) => ({
                ...column,
                sort: true, // Ensure sorting is enabled for each column
                ...tableEvents,
                ...defaultColumnsOption,
              }))
              .concat({ ...actions, ...columnFormatter })}
            rowClasses="table-row"
            onTableChange={onTableChange} // Handle sorting locally
            defaultSorted={defaultSorted} // Set default sorting options
          />
        </div>
      </div>
    </>
  );
};
