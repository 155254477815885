import React from 'react';
import {useParams} from 'react-router';
import {SelectBot} from '../components';

export const DocumentEdit = () => {
  const {documentId} = useParams();

  return (
    <div className="row">
      <div className="col p-0">
        <div className="x_panel">
          <div className="x_content">
            <SelectBot />
            <iframe
              width="100%"
              style={{height: 'calc(100vh - 72px)'}}
              src={`https://docs.google.com/document/d/${documentId}/edit`}
              title="google-doc"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
