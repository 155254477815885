import { createSlice } from '@reduxjs/toolkit';

export const invoiceSlice = createSlice({
  name: 'invoices',
  initialState: {
    invoices: [],
    loading: false,
    error: null,
  },
  reducers: {
    set: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export default invoiceSlice.reducer;
