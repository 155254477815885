import React, {useRef, useState, useEffect, useContext} from 'react';
import {Handle, useStore} from 'reactflow';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onEdit, onSetStart, onClose, onRemove, isLightColor} from '../../helpers/block-helper';
import Select from 'react-select';
import {find as _find} from 'lodash';
import moment from 'moment';
import {User} from '../../states/actions';
import {InputComponent, SelectComp, TextareaComponent, PreviewComponent, NodeComponent, SelectComponent, onEdited, ButtonSwitch} from './Components'
import { GlobalStateContext } from '../GlobalState';


const zoomSelector = (s) => s.transform[2];



export const MultipleChoiceBlock = (node) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);  


  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const flowSelectRef = useRef(null);
  const {_id} = useSelector(User.get());
  const optionSelectRef = useRef(null);
  const bot = useSelector(Chatbot.get());
  const [previewMode, setPreviewMode] = useState(globalState.preview ? undefined : false);
  const zoom = useStore(zoomSelector);



  useEffect(() => {
    setPreviewMode(globalState.preview);
}, [globalState.preview]);

  if (!block) {
    return null;
  }
  const {
    data,
  } = block;



  
 
  const {backgroundColor: bgColor, textColor: txtColor, optionsContent} = data.meta;
  const backgroundColor = bgColor ?? '#2a3f54';
  const textColor = txtColor ?? (isLightColor(backgroundColor) ? '#000000' : '#ffffff');


  return (
    <>
      {!previewMode &&(
          
          <NodeComponent bot={bot} block={block} name="Multiple options" icon="multipleChoice" data={data} node={node} start={start} zoom={zoom}/>
      )}

      {previewMode && (
        //<div className={`block-body`} onClick={review ? null : () => onEdit(node)}>
              <PreviewComponent bot={bot} data={data} node={node} start={start}/>
        //</div>
      )}



     {/*  {(
        <div
          style={{backgroundColor: bgColor ?? '', color: txtColor ?? '#000000'}}
          className={`${data.isProgress === true ? 'isprogress' : ''} node multiple-choice-block`}
          onDoubleClick={review ? null : () => onEdit(node)}
        >
          {start === node.id ? <span className="badge rounded-pill bg-success badge-tl">Start</span> : ''}
          <span className="badge rounded-pill bg-primary badge-bl">{data.variable}</span>
          <div className="my-3" dangerouslySetInnerHTML={{__html: data.content}}></div>
          
          
          <b><p className="mb-0">Options</p></b>
          <div className="h-5rem overflow-auto mb-3 mt-0">
            {data?.options &&
              Object.values(data?.options).map((item, index) => {
                return (
                  <div key={index} className="my-3 position-relative">
                    <span>
                      {item.label} : {item.value}
                    </span>
                  </div>
                );
              })}
          </div>
          <b>
            <p>Flow</p>
          </b>


          {data?.options &&
            Object.values(data?.options)
              .filter((i) => i.hasUniqueFlow)
              .map((item, index) => {
                return (
                  <div key={index} className="my-3 position-relative">
                    <span>{item.value}</span>
                    <Handle id={item.id} className="block-handle block-handle-right" type="source" position="right" />
                  </div>
                );
           })}

          <hr />

          {data.isProgress &&(
          <div className="my-3 h1" dangerouslySetInnerHTML={{__html: data.progress}} />
          )}

        </div>
      )} */}

    </>
  );
};

export const MultiplechoiceEditor = ({nodeid, block, data, options}) => {

    const flowSelectRef = useRef(null);

    /*const {_id} = useSelector(User.get());
    const optionSelectRef = useRef(null);
    const bot = useSelector(Chatbot.get());*/

    const {backgroundColor: bgColor, textColor: txtColor, optionsContent} = data.meta; 

    let variables_selecttype = [
      {
        label: 'select',
        value: 'select',
        content: 'select',
      },
      {
        label: 'radio',
        value: 'radio',
        content: 'radio',
      },
    ];
  

    if (!block) {
      return null;
    }


    const addFlowField = (e) => {
      e.preventDefault();
      let flowSelectOptions = flowSelectRef.current.state.value;
      if (flowSelectOptions) {
        const _option = data?.options[flowSelectOptions.id];
        Chatbot.setBlockData(nodeid, {
          options: {...data?.options, [flowSelectOptions.id]: {..._option, hasUniqueFlow: true}},
        });
        onEdited();
      }
    };
    const removeFlowField = (e, id) => {
      e.preventDefault();
      Chatbot.removeBlockFlow(nodeid, id);
      onEdited();
    };
    const addOptions = (e) => {
      const _options = e.target.value.split(/\n/);
      let optionsToAdd = {};
      for (let i = 0; i < _options.length; i++) {
        const id = moment().add(i, 'ms').valueOf().toString();
        const option = {id, label: '', value: '', hasUniqueFlow: false, next: null};
        const optionToAdd = _options[i];
        if (optionToAdd !== '') {
          let currentOption = null;
          if (optionToAdd.includes('|')) {
            const [label, value] = optionToAdd.split('|');
            option.label = label;
            option.value = value;
            currentOption = _find(options, {label});
          } else {
            option.label = optionToAdd;
            option.value = optionToAdd;
            currentOption = _find(options, {label: optionToAdd});
          }
          if (currentOption && currentOption.id.length > 3) {
            optionsToAdd[currentOption.id] = Object.assign(option, currentOption);
          } else {
            optionsToAdd[id] = option;
          }
        }
      }
      Chatbot.setBlockData(block.id, {
        assign: {...data, options: optionsToAdd, meta: {...data.meta, optionsContent: e.target.value}},
      });
    };
    const handleBlur = (e) => {   
      Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
    };
    return(
      <>
                
                <div className='section'>Options</div>

                {/* <SelectComp data={data} name="Select type" dataname="selecttype" placeholder="Select type" options={variables_selecttype} block={block}/> */}

                <ButtonSwitch name="Type" block={block} data={data} dataname="selecttype" label1="Buttons" label2="Dropdown" state1="radio" state2="select"/>

                <InputComponent data={data} name="Input name" dataname="inputname" onBlur={handleBlur}/>

                <TextareaComponent icon="*" data={data} name="Options" dataname="options" onBlur={addOptions} rows="3" defaultValue={optionsContent}/>
            

                {/* 
                This function will not be in use? 
                <InputComponent data={data} name="Web function (optional)" dataname="webFunction" onBlur={handleBlur}/> */}

                <>

                <label htmlFor="validation" className="form-label comp-header">
                      Add route
                  </label>


                <div className="mb-4 single-input">
                  


                      <Select
                          className="form-control form-control-sm editor-select"
                          classNamePrefix="editor-select"
                          placeholder="Select"
                          id="flows"
                          name="flows"
                          options={data?.options ? Object.values(data?.options) : []}
                          onKeyPress={(e) => (e.key === 'Enter' ? addFlowField(e) : e)}
                          ref={flowSelectRef}
                          onKeyDown={async (e) => {
                            if (e.code === 'Enter' && e.key === 'Enter') {
                              const selectedOption = flowSelectRef.current.state.label;
                              if (selectedOption) {
                                console.log(selectedOption.label);
                              }
                            }
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                              border: 'none',
                            }),
                          }}
                      />
                  
                      <button onClick={(e) => addFlowField(e)} className="fa fa-plus icon"></button>



                  </div>

                  

                
                  {data?.options &&
                    Object.values(data?.options)
                      ?.filter((i) => i.hasUniqueFlow)
                      .map((item) => {
                        return (
                          <>

                            <div key={item.id} className="single-input my-3">
                            
                              <div className="form-control valuefield">{item.label}</div>
                              <button onClick={(e) => removeFlowField(e, item.id)} className="fa fa-trash-alt icon red"></button>

                            </div>
                            
                           </>
                        );
                  })}
                </>


      </>
    )

}