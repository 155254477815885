import React, {useEffect, useState} from 'react';
import {Field} from 'formik';
import {Form} from '../components';
import {axios} from '../libs';

export const Login = () => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    request: '',
  };

  const [createAccount, setCreateAccount] = useState(false);

  const onSuccess = (res) => {};
  const setCreate = () => {
    setCreateAccount(true)
  };
  const setCreateFalse = () => {
    setCreateAccount(false)
  };
  useEffect(() => {
    document.body.classList.add('login');
    document.body.classList.remove('nav-md', 'nav-sm');
  }, []);

  const onGoogleLogin = async () => {
    const {authUrl} = await axios.get('/google-login');
    window.location = authUrl;
  };

  return (
    <>
      <div className="text-center mt-5">
          <div>
             <img width={156} src="/images/logo_one_color.png" alt="Hugo legal logo" className="mb-4 mt-2" />
          </div>
      </div>


      <div className="login">
       
        <div className="login_wrapper">
          
          <div className="login_form login-bg">

          <div className="text-center blue-header">
               <h2>Welcome</h2>
           </div>

            <section className="login_content">
              <Form url="/user-request" initialValues={initialValues} onSuccess={onSuccess}>

                {createAccount &&(
                  <>
                <div>
                  <label htmlFor="firstName" className="left">
                    First Name
                  </label>
                  <Field
                    id="firstName"
                    type="text"
                    name="firstName"
                    className="form-control"
                    placeholder="John"
                    autoComplete="name"
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="left">
                    Last Name
                  </label>
                  <Field
                    id="lastName"
                    type="text"
                    name="lastName"
                    className="form-control"
                    placeholder="Doe"
                    autoComplete="name"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="left">
                    Email
                  </label>
                  <Field
                    id="email"
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="user@domain.com"
                    autoComplete="username"
                  />
                </div>
                {/* <div>
                  <label htmlFor="request" className="left">
                    Message
                  </label>
                  <Field
                    id="request"
                    as="textarea"
                    name="request"
                    className="form-control"
                    placeholder="Hi, my name is John, a lawstudent from Tallin"
                  />
                </div> */}
                <div className="d-grid my-3">
                  <button className="login-btn">Create account</button>
                </div>

                <div onClick={setCreateFalse} className="btn">Back</div>
                </>
                )}

            
              {!createAccount &&(
                  <>           
                      <div className="d-grid">
                        <button onClick={onGoogleLogin} type="button" className="login-btn">
                          <i className="fab fa-google"></i> Sign In with google
                        </button>
                      </div>

                      <div className="d-grid my-3">
                      <div onClick={setCreate} className="login-btn btn-pink">Create account</div>
                      </div>
                      </>
                )}
                     

                
               

              


             
              </Form>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
