import React from 'react';
import {Handle} from 'reactflow';
import {useSelector} from 'react-redux';
import {Chatbot} from '../../states/actions';
import {onRemove, onEdit, onSetStart, onClose, isLightColor} from '../../helpers/block-helper';
import {opertors, operators_date} from '../../config';
import Select from 'react-select';
import {ColorPicker} from '../ColorPicker';
import {IntNodeComponent, InputComponent, SelectComp} from './Components'

export const ConditionBlock = (node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  if (!block) {
    return null;
  }
  const {data, editMode = false} = block;



  return (
    <>
    
      
       <IntNodeComponent name="Filter" icon="condition" data={data} node={node} start={start}/>
       
    </>
  );
};


export const ConditionEditor = ({nodeid, block, data}) => {

  if (!block) {
    return null;
  }
  const variableOptions = Chatbot.getBlockVariables();
 
  const handleBlur = (e) => {   
    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
  };


return (
  <>
      

    <div className='tab-container'>

      <div className={`tab btn-section ${data.method === "date" ? 'active' : ''}`} onClick={() =>  Chatbot.setBlockData(block.id, {method: 'date'})}>
                      <span></span>
                      Date
       </div>
       <div className={`tab btn-section ${data.method === "number" ? 'active' : ''}`} onClick={() => Chatbot.setBlockData(block.id, {method: 'number'})}>
                      <span></span>
                      Number/text
       </div>

       </div>
      
       <br/><br/>
      {data.method === 'number' && ( 
            <>

              <SelectComp data={data} name="Variable" dataname="variable" placeholder="Select" options={variableOptions} block={block} value="label"/>

              <SelectComp data={data} name="Is" dataname="operator" placeholder="Select" options={opertors} block={block}/>

              <InputComponent data={data} name="Value" dataname="value" onBlur={handleBlur}/>
      
            </>
           )}


      {data.method === 'date' && ( 
            <>

              <SelectComp data={data} name="DATE" dataname="variable" placeholder="Select" options={variableOptions} block={block} value="label"/>

              <SelectComp data={data} name="Is" dataname="operator" placeholder="Select" options={operators_date} block={block} value="label"/>

              <SelectComp data={data} name="DATE" dataname="value" placeholder="Select" options={variableOptions} block={block} value="label"/>

            </>
      )}
  </>
  

)
};
